@font-face {
  font-family: 'Montserrat';
  src: local('Montserrat'), url(./assets/fonts/Montserrat-Regular.ttf);
}

body {
  margin: 0;
  font-family: 'Montserrat', sans-serif;
}

body.ReactModal__Body--open {
  overflow: hidden;
}

.ReactModal__Overlay {
  opacity: 0;
  transition: all 200ms ease-in-out;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
}

.ReactModal__Content {
  transform: translateX(-100px);
  transition: all 200ms ease-in-out;
}

.ReactModal__Content--after-open {
  transform: translateX(0px);
}

.ReactModal__Content--before-close {
  transform: translateX(-100px);
}







.ant-descriptions-row:last-child > td {
  padding-bottom: 0;
}

.ant-col-24.ant-form-item-label,
.ant-col-xl-24.ant-form-item-label {
  margin-bottom: 0;
  padding-bottom: 0;
}

.ant-col-24.ant-form-item-control {
  margin-bottom: 0.6em;
}

.ant-table-wrapper.search-results {
  width: 100%;
}

.ant-table-wrapper.search-results .ant-table-row {
  cursor: pointer;
}

.ant-table-wrapper.search-results .ant-table-tbody > tr.ant-table-row.selected > td {
  background-color: #e8ecef;
}

.ant-btn.active {
  background-color: #086592;
  border-color: #086592;
  color: #fff;
}

.ant-radio-group.ant-radio-group-solid.tab-like {
  border-bottom: 1px solid #f0f0f0;
  display: block;
  margin-bottom: 1.4rem;
  text-align: left;
}

.ant-radio-group.ant-radio-group-solid.tab-like .ant-radio-button-wrapper {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.ant-radio-group.ant-radio-group-solid.tab-like .ant-radio-button-wrapper:not(.ant-radio-button-wrapper-checked) {
  border-bottom-color: #fff;
}